export default [
  {
    path: '/payments',
    name: 'payout-list',
    component: () => import('@/views/payout/PayoutList.vue'),
    meta: {
      pageTitle: 'Выплаты',
      breadcrumb: [
        {
          text: 'Выплаты',
          active: true,
        },
      ],
      resource: 'PayoutManage',
      action: 'read',
    },
  },
  {
    path: '/payments/:payoutId',
    name: 'payout-view',
    component: () => import('@/views/payout/ViewUserBonus.vue'),
    meta: {
      pageTitle: 'Выплаты',
      breadcrumb: [
        {
          text: 'Выплаты',
          to: '/payments',
        },
      ],
      resource: 'PayoutManage',
      action: 'read',
    },
  },
]
