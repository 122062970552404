import { ref, watch, computed } from '@vue/composition-api'
import orderRepository from '@/repository/orderRepository'
import chatMessageRepository from '@/repository/chatMessageRepository'
import store from '@/store'
import router from '@/router'
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2'
import basketRepository from '@/repository/basketRepository'
import ability from '@/libs/acl/ability'
import { getUserId } from './userService'

export function elementList() {
  const Columns = [
    { key: 'actions', label: '' },
    { key: 'id', label: 'ИД', sortable: true },
    { key: 'bonusTotal', label: 'Всего бонусов', sortable: true },
    { key: 'bonusApproved', label: 'Одобрено бонусов', sortable: true },
    { key: 'ticketVerification', label: 'Чек', sortable: true },
    { key: 'status', label: 'Статус', sortable: true },
    { key: 'teamId', label: 'Команда' },
    { key: 'createdUser', label: 'Создал' },
    { key: 'updatedUser', label: 'Обновил' },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
    { key: 'updatedAt', label: 'Дата обновления', sortable: true },
  ]

  const refOrderDocuments = ref(null)
  const userId = ref(null)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refOrderDocuments.value ? refOrderDocuments.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'OrderManage')) {
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }

  const refetchData = () => {
    refOrderDocuments.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  const fetchItems = (ctx, callback) => {
    orderRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      userId: userId.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }
        callback(items)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  const clearSelectTable = () => {
    refOrderDocuments.value.clearSelected()
  }
  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrderDocuments,
    Columns,
    clearSelectTable,

    userId,
  }
}

export function getOrder(emit) {
  const orderItems = ref({})

  const Columns = [
    { key: 'articul', label: 'артикул' },
    { key: 'brand', label: 'брэнд' },
    { key: 'title', label: 'товар' },
    { key: 'id', label: '' },
  ]

  const TicketsColumns = [
    { key: 'name', label: 'Товар' },
    { key: 'quantity', label: 'количество', sortable: true },
    { key: 'price', label: 'стоимость', sortable: true },
  ]

  const getElement = id => new Promise(resolve => {
    orderRepository.getOrder(id)
      .then(res => {
        if (!res) {
          router.replace({ name: 'error-404' })
          return
        }
        orderItems.value = { ...res }
        resolve()
      })
  })

  const refetchData = () => {
    const { id } = router.currentRoute.params
    getElement(id).then()
    emit('fetchMessage')
  }

  return {
    orderItems,
    Columns,
    TicketsColumns,
    refetchData,
    getUserId,
  }
}

export const fetchOrderCreateQuantity = async () => {
  if (ability.can('read', 'OrderManage')) {
    orderRepository.elementQuantity({
      statusId: 1,
      ticketVerification: true,

    }).then(value => {
      store.commit('dashboard/SET_ORDER_CREATE_QUANTITY', value || 0)
    })
  }
  return true
}

export const getOrderStatusList = async () => {
  try {
    const orderStatusList = await orderRepository.statusList()
    return orderStatusList.map(x => ({ label: x.title, value: String(x.id) }))
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return []
  }
}

export const addBasketItemTicketElement = async (id, ticketItemsId, refetchData) => {
  try {
    if (!ticketItemsId) {
      await basketRepository.clearTicketElement(id)
    } else {
      await basketRepository.addTicketElement({ id, ticketItemsId })
    }
    store.commit('app/SET_MESSAGE', 'Удаление выполнено успешно!')
    refetchData()
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    refetchData()
  }
}

export const updateStatus = async (id, statusId, message, refetchData) => {
  try {
    const result = await Swal.fire({
      title: 'Подтверждение изменения статуса заказа!',
      text: 'Вы уверены в необходимости изменить статус заказа?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Подтвердить',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ml-1',
      },
      buttonsStyling: false,
    })

    if (result.value) {
      const resultUpdate = await orderRepository.updateOrder(id, { statusId, message })

      if (parseInt(statusId, 10) === 3 || parseInt(statusId, 10) === 4) await chatMessageRepository.createChatMessage({ parentId: id, message, type: 'order' })

      if (resultUpdate) {
        store.commit('app/SET_MESSAGE', 'Статус заказа успешно изменен!')
        fetchOrderCreateQuantity()
        refetchData()
      }
    }
  } catch (error) {
    store.commit('app/SET_ERROR', error)
  }
}

export function editComments() {
  const chatInputMessage = ref('')
  const messages = ref()

  const createOrderMessage = orderId => new Promise((resolve, reject) => {
    chatMessageRepository.createChatMessage({
      parentId: orderId,
      type: 'order',
      message: chatInputMessage.value,
    })
      .then(res => {
        if (!res) {
          throw new Error('Комментарий не был создан')
        }

        chatInputMessage.value = ''
        resolve()
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
        reject(error)
      })
  })

  const getChatOrderMessage = orderId => new Promise((resolve, reject) => {
    chatMessageRepository.getChatMessage(orderId, 'order')
      .then(res => {
        if (!res) {
          throw new Error('Комментарий для текущего заказа не найден')
        }

        const { items } = res
        messages.value = items

        resolve()
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
        reject(error)
      })
  })

  return {
    chatInputMessage,
    messages,
    getChatOrderMessage,
    createOrderMessage,
  }
}

export function elementListByCreatedId() {
  const Columns = [
    { key: 'actions', label: '' },
    { key: 'id', label: 'ИД', sortable: true },
    { key: 'bonusTotal', label: 'Всего бонусов', sortable: true },
    { key: 'bonusApproved', label: 'Одобрено бонусов', sortable: true },
    { key: 'status', label: 'Статус' },
    { key: 'teamId', label: 'Команда' },
    { key: 'createdUser', label: 'Создал' },
    { key: 'updatedUser', label: 'Обновил' },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
    { key: 'updatedAt', label: 'Дата обновления', sortable: true },
  ]

  const refOrderDocuments = ref(null)
  const userId = parseInt(router.currentRoute.params.id, 10)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isLoading = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refOrderDocuments.value ? refOrderDocuments.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refOrderDocuments.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  const fetchItems = (ctx, callback) => {
    orderRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      createdId: userId,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items)
        totalItems.value = count
        isLoading.value = false
      })
      .catch(error => {
        isLoading.value = false
        store.commit('app/SET_ERROR', error)
      })
  }

  const clearSelectTable = () => {
    refOrderDocuments.value.clearSelected()
  }
  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrderDocuments,
    Columns,
    clearSelectTable,
    isLoading,

    userId,
  }
}

export const fetchStatisticOrderQuantityAndBonus = async () => {
  try {
    if (ability.can('read', 'OrderManage')) {
      const statistics = await orderRepository.statistic()
      return [{
        title: 'Количество продаж',
        statistics: statistics[0] || {},
      }, {
        title: 'Сумма бонусов за продажи',
        statistics: statistics[1] || {},
      }]
    }
    return []
  } catch (error) {
    return []
  }
}

export const fetchStatisticOrderUserQuantity = async isNewUser => {
  try {
    if (ability.can('read', 'OrderManage')) {
      const params = isNewUser ? { isNewUser: true } : null
      const statistics = await orderRepository.statisticUser(params)
      return {
        title: isNewUser ? 'Активные новые пользователи' : 'Активные пользователи',
        statistics: statistics || {},
      }
    }
    return null
  } catch (error) {
    return null
  }
}
