import { ref } from '@vue/composition-api'

import usersMotivationRepository from '@/repository/usersMotivationRepository'
import store from '@/store'
import ability from '@/libs/acl/ability'

export const fetchUserMotivationCreateQuantity = async () => {
  if (ability.can('read', 'UsersMotivation')) {
    usersMotivationRepository.elementQuantity({
      isChanged: 1,
    }).then(value => {
      store.commit('dashboard/SET_USER_MOTIVATION_CREATE_QUANTITY', value || 0)
    })
  }
  return true
}

export default function moderationList() {
  const userData = ref(null)
  const errorData = ref(null)

  const getElement = id => new Promise(resolve => {
    if (!ability.can('read', 'UsersMotivation')) {
      errorData.value = 'Недостаточно прав для чтения'
      return
    }
    usersMotivationRepository.getElement(id)
      .then(res => {
        if (!res) {
          throw new Error('Статьи с текущем идентификатором не найдено')
        }
        userData.value = { ...res }
        resolve()
      })
      .catch(error => {
        errorData.value = error ? `${error}`.replace('Error: ', '') : ''
      })
  })

  const updateElement = (id, { status, comments } = {}) => new Promise(resolve => {
    if (!ability.can('update', 'UsersMotivation')) {
      store.commit('app/SET_ERROR', 'Недостаточно прав для изменения')
      return
    }
    const param = new FormData()
    param.append('status', status)
    if (comments) {
      param.append('comments', comments)
    }
    usersMotivationRepository.update(id, param)
      .then(res => {
        fetchUserMotivationCreateQuantity()
        resolve(res)
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  })

  return {
    userData,
    errorData,
    getElement,
    updateElement,
  }
}
