<template>
  <div class="btn-floating-action">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="info"
      size="lg"
      class="btn-icon rounded-circle"
      :disabled="loading"
      @click="addFoto"
    >
      <feather-icon
        v-if="!loading"
        icon="CameraIcon"
        size="26"
      />
      <b-spinner v-if="loading" />
    </b-button>
    <input
      id="input-file-cart"
      type="file"
      style="display: none;"
      @change="onSubmit"
    >
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BButton, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { addFoto, handleFileChange } from '@/application/basketService'
import store from '@/store'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BSpinner,
  },
  setup() {
    const loading = ref(false)

    const onSubmit = async event => {
      try {
        loading.value = true
        await handleFileChange(event)
        await store.dispatch('basket/getbasketUsed')
        store.commit('app/SET_MESSAGE', 'Товар успешно добавлен.')
        loading.value = false
      } catch (error) {
        loading.value = false
        store.commit('app/SET_ERROR', error)
      }
    }

    return {
      loading, onSubmit, addFoto,
    }
  },

}
</script>

<style lang="scss" scoped>
.btn-floating-action {
  position: fixed;
    bottom: 65px;
    right: 30px;
    z-index: 1050;

    opacity: 1;
    // transform: translateY(100px);
    transition: all .5s ease;
    &.btn.btn-lg.btn-icon {
      padding: 1.6rem;
    }
}
</style>
