import conect from './api'

const { axiosIns } = conect()

export default {
  async signIn(...params) {
    const { error, result } = (await axiosIns.post('/auth/signin/', ...params)).data
    if (error) throw error

    return result
  },
  async refreshToken(...params) {
    const { error, result } = (await axiosIns.post('/auth/refresh/', ...params)).data
    if (error) throw error

    return result
  },
  async registerCodeCreate(...params) {
    const { error, result } = (await axiosIns.post('/auth/register-code-create/', ...params)).data
    if (error) throw error

    return result
  },
  async register(...params) {
    const { error, result } = (await axiosIns.post('/auth/register/', ...params)).data
    if (error) throw error

    return result
  },
  async authCodeCreate(...params) {
    const { error, result } = (await axiosIns.post('/auth/signin-code-create/', ...params)).data
    if (error) throw error

    return result
  },
  async authCode(...params) {
    const { error, result } = (await axiosIns.post('/auth/signin-code/', ...params)).data
    if (error) throw error

    return result
  },
}
