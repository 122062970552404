export default {
  namespaced: true,
  state: {
    accessToken: null,
  },
  getters: {},
  mutations: {
    SET_TOKEN(state, token) {
      state.accessToken = token
    },
  },
  actions: {},
}
