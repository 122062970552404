export default [
  {
    path: '/econfirm',
    name: 'emailconfirm',
    component: () => import('@/views/emailconfirm/Emailconfirm.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      // action: 'read',
    },
  },
]
