import conect from './api'

const { axiosIns } = conect()

export default {
  async elementList(params = {}) {
    const { error, result } = (await axiosIns.get('/payout/', { params })).data

    if (error) throw error

    return result
  },

  async update(id, params) {
    const { error, result } = (await axiosIns.put(`/payout/${id}`, params)).data
    if (error) throw error

    return result
  },

  async statusList() {
    const { error, result } = (await axiosIns.get('/payout/status')).data
    if (error) throw error

    return result
  },

  async getElementById(id, params) {
    const { error, result } = (await axiosIns.get(`/payout/${id}`, { params })).data
    if (error) throw error

    return result
  },
  async createElement(...params) {
    const { error, result } = (await axiosIns.post('/payout', ...params)).data
    if (error) throw error

    return result
  },
  async elementQuantity(params = {}) {
    const { error, result } = (await axiosIns.get('/payout/quantity/', { params })).data
    if (error) throw error

    return result
  },
  async statistic(params = {}) {
    const { error, result } = (await axiosIns.get('/payout/statistic', { params })).data
    if (error) throw error

    return result
  },
}
