export default {
  namespaced: true,
  state: {
    baskets: [],
    basket: {
      id: null,
      teamId: null,
      title: null,
      createdId: null,
      isDefault: null,
      basketTicket: null,
      items: [],
    },

  },
  getters: {},
  mutations: {
    SET_BASKET_LIST(state, baskets) {
      state.baskets = (baskets || []).length > 0 ? [...baskets] : []
    },
    SET_BASKET(state, {
      id, teamId, title, createdId, isDefault, items, basketTicket,
    } = {}) {
      Object.assign(state.basket, {
        id, teamId, title, createdId, isDefault, basketTicket, items: [...items || []],
      })
    },
  },
  actions: {
    // async basketChange({ dispatch }, id) {
    //   await basketRepository.basketChange(id)
    //   await dispatch('getbasketUsed')
    // },
    // async getbasketList({ commit }) {
    //   const baskets = await basketRepository.basketList()
    //   commit('SET_BASKET_LIST', baskets)
    // },
    // async getbasketUsed({ commit }) {
    //   const basket = await basketRepository.basketUsed()
    //   commit('SET_BASKET', basket)
    // },
    // async chekbasket({ dispatch }) {
    //   await Promise.all([
    //     dispatch('getbasketList'),
    //     dispatch('getbasketUsed'),
    //   ])
    // },
    // async deleteBasketItem({ dispatch }, id) {
    //   await basketRepository.deleteBasketItem(id)
    //   await dispatch('getbasketUsed')
    // },
    // async relocateBasketItem({ dispatch }, { id, multibasketId }) {
    //   await basketRepository.relocateBasketItem(id, { multibasketId })
    //   await dispatch('getbasketUsed')
    // },
    // async createBasket({ dispatch }, ...params) {
    //   // console.log(teamId, title)
    //   await basketRepository.createBasket(...params)
    //   await dispatch('chekbasket')
    // },
    // async deleteBasket({ dispatch }, id) {
    //   await basketRepository.deleteBasket(id)
    //   await dispatch('chekbasket')
    // },
    // async addCashReceipt({ dispatch }, ...params) {
    //   await basketRepository.addCashReceipt(...params)
    //   await dispatch('chekbasket')
    // },
    // async deleteCashReceipt({ dispatch }, id) {
    //   await basketRepository.deleteCashReceipt(id)
    //   await dispatch('chekbasket')
    // },
    // async createOrder({ dispatch, state }) {
    //   const multibasketId = state.basket.id
    //   const basketIds = state.basket.items.map(x => x.id)
    //   await orderRepository.createOrder({ multibasketId, basketIds })
    //   await dispatch('chekbasket')
    // },
  },
}
