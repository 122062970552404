import { fetchOrderCreateQuantity, fetchStatisticOrderQuantityAndBonus, fetchStatisticOrderUserQuantity } from '@/application/orderService'
import { fetchPayoutCreateQuantity, fetchStatisticPayoutQuantityAndBonus } from '@/application/payoutService'
import { fetchUserMotivationCreateQuantity } from '@/application/moderationService'
import { fetchTicketVerificationQuantity } from '@/application/ticketVerificationQueueService'
import { fetchQuickSummaryUserCreate } from '@/application/userService'
import { fetchStatisticOrderStart } from '@/application/userMotivationService'

export const fetchModeratorData = async () => {
  await Promise.all([
    fetchOrderCreateQuantity(),
    fetchPayoutCreateQuantity(),
    fetchUserMotivationCreateQuantity(),
    fetchTicketVerificationQuantity(),
  ])
  return true
}

export const fetchQuickSummary = async () => {
  const result = []
  const items = await Promise.all([
    fetchQuickSummaryUserCreate(),
    fetchStatisticOrderUserQuantity(),
    fetchStatisticOrderUserQuantity(true),
    fetchStatisticOrderQuantityAndBonus(),
    fetchStatisticPayoutQuantityAndBonus(),
    fetchStatisticOrderStart(),
  ])
  items.forEach(item => {
    if (item) {
      if (Array.isArray(item)) {
        result.push(...item)
      } else {
        result.push(item)
      }
    }
  })
  return result
}
