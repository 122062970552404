import conect from './api'

const { axiosIns } = conect()

export default {
  async createChatMessage(params) {
    const { error, result } = (await axiosIns.post('/chat/message', params)).data
    if (error) throw error

    return result
  },
  async getChatMessage(orderId, type) {
    const { error, result } = (await axiosIns.get(`/chat/${type}/${orderId}`)).data
    if (error) throw error

    return result
  },
}
