export default [
  {
    path: '/bonus',
    name: 'bonus-list',
    component: () => import('@/views/bonus/bonus-list/BonusList.vue'),
    meta: {
      pageTitle: 'Мои бонусы',
      breadcrumb: [
        {
          text: 'Мои бонусы',
          active: true,
        },
      ],
      resource: 'User',
      action: 'read',
    },
  },
]
