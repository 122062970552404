import conect from './api'

const { axiosIns } = conect()

export default {
  async createOrder(...params) {
    const { error, result } = (await axiosIns.post('/order', ...params)).data
    if (error) throw error

    return result
  },
  async elementList(params = {}) {
    const { error, result } = (await axiosIns.get('/order/', { params })).data
    if (error) throw error

    return result
  },
  async updateOrder(id, params) {
    const { error, result } = (await axiosIns.put(`/order/${id}`, params)).data
    if (error) throw error

    return result
  },
  async getOrder(id) {
    const { error, result } = (await axiosIns.get(`/order/${id}`)).data
    if (error) throw error
    return result
  },
  async updateOrderMessage(id, params) {
    const { error, result } = (await axiosIns.put(`/order-message/${id}`, params)).data
    if (error) throw error
    return result
  },

  async statusList() {
    const { error, result } = (await axiosIns.get('/order/status/')).data
    if (error) throw error

    return result
  },
  async elementQuantity(params = {}) {
    const { error, result } = (await axiosIns.get('/order/quantity/', { params })).data
    if (error) throw error

    return result
  },
  async statistic(params = {}) {
    const { error, result } = (await axiosIns.get('/order/statistic', { params })).data
    if (error) throw error

    return result
  },
  async statisticUser(params = {}) {
    const { error, result } = (await axiosIns.get('/order/statistic-user', { params })).data
    if (error) throw error

    return result
  },
}
