import conect from './api'

const { axiosIns } = conect()

export default {
  async addFoto(...params) {
    const { error, result } = (await axiosIns.post('/basket-item/image', ...params)).data
    if (error) throw error

    return result
  },
  async basketList(params) {
    const { error, result } = (await axiosIns.get('/basket/all', { params })).data
    if (error) throw error

    return result
  },

  async getBasketById(id) {
    const { error, result } = (await axiosIns.get(`/basket/${id}`)).data
    if (error) throw error
    return result
  },

  async basketUsed(params) {
    const { error, result } = (await axiosIns.get('/basket-item/used', { params })).data
    if (error) throw error

    return result
  },
  async basketChange(...params) {
    const { error, result } = (await axiosIns.post('/basket/change-basket', ...params)).data
    if (error) throw error

    return result
  },
  async deleteBasketItem(id) {
    const { error, result } = (await axiosIns.delete(`/basket-item/${id}`)).data
    if (error) throw error

    return result
  },
  async relocateBasketItem(id, ...params) {
    const { error, result } = (await axiosIns.put(`/basket-item/${id}`, ...params)).data
    if (error) throw error

    return result
  },
  async addTicketElement(...params) {
    const { error, result } = (await axiosIns.post('/basket-item/ticket-item', ...params)).data
    if (error) throw error

    return result
  },
  async clearTicketElement(id) {
    const { error, result } = (await axiosIns.delete('/basket-item/ticket-item', { data: { id } })).data
    if (error) throw error

    return result
  },
  async createBasket(...params) {
    const { error, result } = (await axiosIns.post('/basket', ...params)).data
    if (error) throw error

    return result
  },
  async createBasketTicket(...params) {
    const { error, result } = (await axiosIns.post('/basket/ticket-string', ...params)).data
    if (error) throw error

    return result
  },
  async deleteBasket(id) {
    const { error, result } = (await axiosIns.delete(`/basket/${id}`)).data
    if (error) throw error

    return result
  },

  async deleteBasketTicket(id) {
    const { error, result } = (await axiosIns.delete(`/basket/ticket/${id}`)).data
    if (error) throw error

    return result
  },

  async addCashReceipt(...params) {
    const { error, result } = (await axiosIns.post('/basket/cashreceipt', ...params)).data
    if (error) throw error

    return result
  },
  async deleteCashReceipt(id) {
    const { error, result } = (await axiosIns.delete(`/basket/cashreceipt/${id}`)).data
    if (error) throw error

    return result
  },
  async updateBasketTicket(id, ...params) {
    const { error, result } = (await axiosIns.put(`/basket/ticket/${id}`, ...params)).data
    if (error) throw error

    return result
  },

  async addSellerFromTicket(...params) {
    const { error, result } = (await axiosIns.post('/basket-ticket/add-seller', ...params)).data
    if (error) throw error

    return result
  },

  async ticketVerificationFromJSON(...params) {
    const { error, result } = (await axiosIns.post('/basket-ticket/verification-json', ...params)).data
    if (error) throw error

    return result
  },
}
