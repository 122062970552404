import conect from './api'

const { axiosIns } = conect()

export default {
  async update(userId, ...args) {
    const { error, result } = (await axiosIns.put(`/user-company/${userId}`, ...args)).data
    if (error) throw error

    return result
  },
  async getElement(userId) {
    const { error, result } = (await axiosIns.get(`/user-company/${userId}`)).data
    if (error) throw error

    return result
  },
}
