export default [
  {
    path: '/basket',
    name: 'basket',
    component: () => import('@/views/basket/Basket.vue'),
    meta: {
      pageTitle: 'Корзина',
      contentClass: 'ecommerce-application',
      breadcrumb: [],
      resource: 'User',
      action: 'read',
    },
  },
]
