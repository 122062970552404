export default [
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/company/CompanyList.vue'),
    meta: {
      pageTitle: 'Компании клиентов',
      //   breadcrumb: [],
      resource: 'dashboard',
      action: 'read',
    },
  },
]
