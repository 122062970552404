export default [
  {
    path: '/sale-admin',
    name: 'sale-admin-list',
    component: () => import('@/views/sale/sale-admin/SaleAdminList.vue'),
    meta: {
      pageTitle: 'Список продаж пользователя',
      breadcrumb: [
        {
          text: 'Список продаж пользователя',
          active: true,
        },
      ],
      resource: 'OrderManage',
      action: 'read',
    },
  },
  {
    path: '/sale-admin/:id',
    name: 'sale-admin-view',
    component: () => import('@/views/sale/sale-admin/SaleAdminView.vue'),
    meta: {
      pageTitle: 'Продаж пользователя',
      breadcrumb: [
        {
          text: 'Список продаж пользователя',
          to: '/sale-admin',
        },
      ],
      resource: 'OrderManage',
      action: 'read',
    },
  },
]
