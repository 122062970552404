import conect from './api'

const { axiosIns } = conect()

export default {
  async bonusBalance() {
    const { error, result } = (await axiosIns.get('/user/bonus-balance')).data
    if (error) throw error

    return result
  },
  async getBalanceUser(userId) {
    const { error, result } = (await axiosIns.get(`/bonus/balance/${userId}`)).data
    if (error) throw error

    return result
  },
  async bonusDocumentList(params) {
    const { error, result } = (await axiosIns.get('/bonus/document', { params })).data

    if (error) throw error

    return result
  },
  async getTotalBonuses(params) {
    const { error, result } = (await axiosIns.get('/bonus/total-bonuses', { params })).data
    if (error) throw error

    return result
  },
}
