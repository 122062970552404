import axios from '@axios'
import store from '@/store'
import {
  getToken, refresh, setToken, setRefreshToken,
} from '@/application/authService'

class Conect {
    // Will be used by this service for making API calls
    axiosIns = null

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns) {
      this.axiosIns = axiosIns

      this.axiosIns.defaults.baseURL = process.env.VUE_APP_BASE_URL
      // eslint-disable-next-line no-param-reassign
      this.axiosIns.defaults.headers['Access-Control-Allow-Origin'] = '*'

      // Request Interceptor
      this.axiosIns.interceptors.request.use(
        config => {
          // Get token from localStorage
          const accessToken = getToken()

          // If token is present add it to request's Authorization Header
          if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${accessToken}`
          }

          // eslint-disable-next-line no-param-reassign
          config.headers['Application-sid'] = store.state.app.deviceId || ''

          return config
        },
        error => Promise.reject(error),
      )

      // Add request/response interceptor
      this.axiosIns.interceptors.response.use(
        response => response,
        error => {
          // const { config, response: { status } } = error
          const { config, response } = error
          const originalRequest = config

          // if (status === 401) {
          if (response && response.status === 401) {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true
              refresh().then(r => {
                this.isAlreadyFetchingAccessToken = false

                // Update accessToken in localStorage
                setToken(r.data.accessToken)
                setRefreshToken(r.data.refreshToken)

                this.onAccessTokenFetched(r.data.accessToken)
              })
            }
            const retryOriginalRequest = new Promise(resolve => {
              this.addSubscriber(accessToken => {
                // Make sure to assign accessToken according to your response.
                // Check: https://pixinvent.ticksy.com/ticket/2413870
                // Change Authorization header
                originalRequest.headers.Authorization = `Bearer ${accessToken}`
                resolve(this.axiosIns(originalRequest))
              })
            })
            return retryOriginalRequest
          }
          return Promise.reject(error)
        },
      )
    }

    onAccessTokenFetched(accessToken) {
      this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
      this.subscribers.push(callback)
    }
}

const conect = new Conect(axios)

export default () => ({ axiosIns: conect.axiosIns })
