import userCompanyRepository from '@/repository/userCompanyRepository'
import store from '@/store'

export const getElementById = async id => {
  try {
    const result = await userCompanyRepository.getElement(id)

    return result
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return {}
  }
}

export const update = async (userId, param) => {
  try {
    const result = await userCompanyRepository.update(userId, param)

    return result
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return {}
  }
}
