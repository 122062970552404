import store from '@/store'
import ability from '@/libs/acl/ability'
import userRepository from '@/repository/userRepository'
import { getElementById } from '@/application/userCompanyService'
import { ref } from '@vue/composition-api'
import { getBonusUserList } from '@/application/bonusService'

// eslint-disable-next-line import/no-cycle
import router from '@/router'

export function getUserId() {
  return store.state.user.userId
}

export const verifemail = async emailToken => {
  const emailconfirm = await userRepository.verifemail({ emailToken })
  store.commit('user/SET_EMAIL_CONFIRM', emailconfirm)
}

export const getUserData = async userId => {
  const userData = await userRepository.getUserData(userId)
  store.commit('user/UPDATE_USER_DATA', userData)
}
export const getUserProfile = async userId => {
  const userProfile = await userRepository.getUserProfile(userId)
  return userProfile
}
export const fetchUserData = async () => {
  const userId = getUserId()
  // подгружаем данные по пользаку
  await getUserData(userId)
  ability.update(store.state.user.userData.ability)

  return true
}
export const fetchUserProfile = () => {
  const profile = ref({})
  const profileAvatar = ref({})
  const userCompany = ref({})
  const bonusesList = ref({})
  const errorData = ref(false)

  const getElement = id => new Promise(resolve => {
    userRepository.getUserProfile(id)
      .then(res => {
        if (!res) {
          router.replace({ name: 'error-404' })
          return
        }
        const { avatar, ...userData } = res.general

        profile.value = userData
        profileAvatar.value = avatar
        resolve()
      }).then(async () => {
        bonusesList.value = await getBonusUserList({ userId: id }) || {}
      })
      .then(async () => {
        const {
          taxNumber, name, address, departmentStore,
        } = await getElementById(id) || {}

        userCompany.value = {
          taxNumber, name, address, departmentStore,
        }
      })
  }).catch(() => { errorData.value = true })

  const refetchData = () => {
    getElement(parseInt(router.currentRoute.params.id, 10)).then()
  }
  return {
    refetchData,
    profile,
    userCompany,
    profileAvatar,
    bonusesList,
    getElement,
    errorData,
  }
}

export const fetchQuickSummaryUserCreate = async () => {
  try {
    if (ability.can('read', 'UserManage')) {
      const userCreate = await userRepository.statisticReg()
      return {
        title: 'Зарегистрированные пользователи',
        statistics: userCreate || {},
      }
    }
    return null
  } catch (error) {
    return null
  }
}
