export default [
  {
    path: '/actions',
    component: {
      render(c) { return c('router-view') },
    },
    children: [
      {
        path: '',
        name: 'action-list',
        component: () => import('@/views/action/action-list/ActionList.vue'),
        meta: {
          pageTitle: 'Акции',
          breadcrumb: [
            {
              text: 'Акции',
              active: true,
            },
          ],
          resource: 'Action',
          action: 'read',
        },
      },
      {
        path: ':id',
        name: 'action-view',
        component: () => import('@/views/action/action-view/ActionView.vue'),
        meta: {
          pageTitle: 'Акции',
          breadcrumb: [
            {
              text: 'Акции',
              to: '/actions',
            },
          ],
          resource: 'Action',
          action: 'read',
        },
      },
    ],
  },
]
