import ticketVerificationQueueRepository from '@/repository/ticketVerificationQueueRepository'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import ability from '@/libs/acl/ability'
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2'

export function getElementList() {
  const Columns = [
    { key: 'actions', label: '' },
    { key: 'createdUser', label: 'Пользователь' },
    { key: 'status', label: 'Статус', sortable: true },
    { key: 'orderId', label: 'Номер заказа', sortable: true },
    { key: 'multibasketId', label: 'Id корзины', sortable: true },
    { key: 'operationType', label: 'Тип операции' },
    { key: 'receiveDate', label: 'Дата создания чека', sortable: true },
    { key: 'createdAt', label: 'Дата создания очереди', sortable: true },
  ]

  const refTicketQueue = ref(null)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refTicketQueue.value ? refTicketQueue.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'OrderManage')) {
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }

  const refetchData = () => {
    refTicketQueue.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    ticketVerificationQueueRepository.getElementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'asc' : 'desc',
      sidx: sortBy.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }
        // const ticketQueue = items.map(x => {
        //   const {
        //     id,
        //     createdAt,
        //     status,
        //     orderId,
        //     receiveDate,
        //     operationType,
        //     createdUser: user,
        //   } = x
        //   return {
        //     id,
        //     createdAt,
        //     status,
        //     orderId,
        //     receiveDate,
        //     operationType,
        //     userId: user.id,
        //     phone: user.phone,
        //     firstName: user.firstName,
        //     lastName: user.lastName,
        //     file: user.file,
        //   }
        // })

        callback(items)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }
  const clearSelectTable = () => {
    refTicketQueue.value.clearSelected()
  }

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTicketQueue,
    Columns,
    clearSelectTable,

    refetchData,

  }
}

export const fetchTicketVerificationQuantity = async () => {
  if (ability.can('read', 'OrderManage')) {
    ticketVerificationQueueRepository.elementQuantity().then(value => {
      store.commit('dashboard/SET_TICKET_VERIFICATION_QUANTITY', value || 0)
    })
  }
  return true
}

export const deleteTicketQueue = async (id, refetchData) => {
  const result = await Swal.fire({
    title: 'Подтверждение удаления очереди чека!',
    text: 'Вы уверены в необходимости удалить очередь чека?',
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: 'Отмена',
    confirmButtonText: 'Подтвердить',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-primary ml-1',
    },
    buttonsStyling: false,
  })

  if (result.value) {
    ticketVerificationQueueRepository.deleteTicketQueue(id).then(() => {
      store.commit('app/SET_MESSAGE', 'Очередь чека успешно удалена!')
      fetchTicketVerificationQuantity()
      if (refetchData) {
        refetchData()
      }
    }).catch(error => {
      store.commit('app/SET_ERROR', error)
    })
  }
}
