import payoutRepository from '@/repository/payoutRepository'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import ability from '@/libs/acl/ability'
import router from '@/router'

export function elementList() {
  const Columns = [
    { key: 'id', label: 'ИД', sortable: true },
    { key: 'bonus', label: 'Бонусы', sortable: true },
    { key: 'status', label: 'Статус' },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
    { key: 'updatedAt', label: 'Дата изменения', sortable: true },
  ]

  const refPayout = ref(null)
  const userId = parseInt(router.currentRoute.params.id, 10)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isLoading = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refPayout.value ? refPayout.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'PayoutManage')) {
    isLoading.value = false
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }

  const refetchData = () => {
    refPayout.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  const fetchItems = (ctx, callback) => {
    payoutRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      userId,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items)
        totalItems.value = count
        isLoading.value = false
      })
      .catch(error => {
        isLoading.value = false
        store.commit('app/SET_ERROR', error)
      })
  }

  const clearSelectTable = () => {
    refPayout.value.clearSelected()
  }
  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPayout,
    Columns,
    clearSelectTable,

    isLoading,
  }
}

export function payoutUsersList() {
  const Columns = [
    { key: 'id', label: 'ИД', sortable: true },
    { key: 'userName', label: 'Пользователь' },
    { key: 'bonus', label: 'Бонусы', sortable: true },
    { key: 'payoutStatus', label: 'Статус' },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
    { key: 'updatedAt', label: 'Дата изменения', sortable: true },
  ]

  const refPayout = ref(null)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const byStatusId = ref(null)
  const attributes = ['*', 'status', 'user']

  const dataMeta = computed(() => {
    const localItemsCount = refPayout.value ? refPayout.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'PayoutManage')) {
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }
  if (!ability.can('update', 'PayoutManage') && ability.can('payout', 'PayoutManage')) byStatusId.value = 4
  const refetchData = () => {
    refPayout.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    payoutRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      attributes,
      statusId: byStatusId.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }
        const payoutList = items.map(x => {
          attributes.slice(1).forEach(attribute => {
            if (!x[attribute]) throw new Error('недостаточно данных')
          })

          const {
            id, bonus, userId, createdAt, updatedAt, status: payoutStatus, user,
          } = x
          return {
            id, bonus, userId, createdAt, updatedAt, payoutStatus, phone: user.phone, firstName: user.firstName, lastName: user.lastName, file: user.file,
          }
        })
        callback(payoutList)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }
  const clearSelectTable = () => {
    refPayout.value.clearSelected()
  }

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPayout,
    Columns,
    clearSelectTable,
    byStatusId,

    refetchData,

  }
}

export function payoutDataList() {
  const Columns = [
    { key: 'id', label: 'ИД', sortable: true },
    { key: 'bonus', label: 'Бонусы', sortable: true },
    { key: 'status', label: 'Статус' },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
    { key: 'updatedAt', label: 'Дата изменения', sortable: true },
  ]

  const refPayout = ref(null)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isLoading = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refPayout.value ? refPayout.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'PayoutManage')) {
    isLoading.value = false
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }

  const refetchData = () => {
    refPayout.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  const fetchItems = (ctx, callback) => {
    payoutRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items)
        totalItems.value = count
        isLoading.value = false
      })
      .catch(error => {
        isLoading.value = false
        store.commit('app/SET_ERROR', error)
      })
  }

  const clearSelectTable = () => {
    refPayout.value.clearSelected()
  }
  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPayout,
    Columns,
    clearSelectTable,

    isLoading,
  }
}

export const fetchPayoutCreateQuantity = async () => {
  if (ability.can('read', 'PayoutManage')) {
    payoutRepository.elementQuantity({
      statusId: ability.can('payout', 'PayoutManage') && !ability.can('manage', 'all') ? 4 : 1,
    }).then(value => {
      store.commit('dashboard/SET_PAYOUT_CREATE_QUANTITY', value || 0)
    })
  }
  return true
}

export const updateElement = (id, {
  status, statusId, bonus, comment,
} = {}) => new Promise(resolve => {
  const param = new FormData()
  param.append('status', status)
  param.append('statusId', statusId)
  param.append('bonus', bonus)
  if (comment) param.append('comment', comment)

  payoutRepository.update(id, param)
    .then(res => {
      fetchPayoutCreateQuantity()
      resolve(res)
    })
    .catch(error => {
      store.commit('app/SET_ERROR', error)
    })
})

export const getElementById = async payoutId => {
  try {
    const attributes = ['*', 'status', 'payoutData', 'user']
    const payoutUserData = await payoutRepository.getElementById(payoutId, { attributes })

    attributes.slice(1).forEach(attribute => {
      if (!payoutUserData[attribute]) throw new Error('недостаточно данных')
    })
    const {
      id, createdAt, updatedAt, status, bonus, comment,
    } = payoutUserData

    return {
      userPayout: { ...payoutUserData.user },
      payout: {
        id, createdAt, updatedAt, status, bonus, comment,
      },
      payouts: { ...payoutUserData.payoutData },
    }
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return {}
  }
}

export const getPayoutStatusList = async () => {
  try {
    const payoutStatusList = await payoutRepository.statusList()

    return payoutStatusList.map(x => ({ label: x.title, value: String(x.name), id: parseInt(x.id, 10) }))
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return []
  }
}

export const createElement = async params => {
  try {
    const result = await payoutRepository.createElement(params)

    return result
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return {}
  }
}

export const fetchStatisticPayoutQuantityAndBonus = async () => {
  try {
    if (ability.can('read', 'OrderManage')) {
      const statistics = await payoutRepository.statistic()
      return [{
        title: 'Количество выплат',
        statistics: statistics[0] || {},
      }, {
        title: 'Сумма выплат',
        statistics: statistics[1] || {},
      }]
    }
    return []
  } catch (error) {
    return []
  }
}
