export default [
  {
    path: '/application-form-list',
    name: 'application-form-list',
    component: () => import('@/views/applicationForm/ApplicationFormList.vue'),
    meta: {
      pageTitle: 'Список заявок',
      //   breadcrumb: [],
      resource: 'ApplicationFormWebsite',
      action: 'read',
    },
  },
]
