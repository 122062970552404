import bonusRepository from '@/repository/bonusRepository'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'

export default function getBonusDocuments() {
  const Columns = [
    { key: 'id', label: 'ИД', sortable: true },
    { key: 'bonus', label: 'Бонусы', sortable: true },
    { key: 'comment', label: 'Комментарий' },
    { key: 'documentTypeTitle', label: 'Тип начисления' },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
    { key: 'updatedAt', label: 'Дата изменения', sortable: true },
  ]

  const refBonusDocument = ref(null)
  const userId = parseInt(router.currentRoute.params.id, 10)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isLoading = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refBonusDocument.value ? refBonusDocument.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'UserManage')) {
    isLoading.value = false
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }

  const refetchData = () => {
    refBonusDocument.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  const fetchItems = (ctx, callback) => {
    bonusRepository.bonusDocumentList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      userId,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items)
        totalItems.value = count
        isLoading.value = false
      })
      .catch(error => {
        isLoading.value = false
        store.commit('app/SET_ERROR', error)
      })
  }

  const clearSelectTable = () => {
    refBonusDocument.value.clearSelected()
  }
  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBonusDocument,
    Columns,
    clearSelectTable,

    isLoading,
    userId,
  }
}

export const getBonusUserList = async userId => {
  try {
    const bonuses = await bonusRepository.getTotalBonuses(userId)

    return bonuses
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return []
  }
}
export const getBalanceUser = async userId => {
  try {
    const bonuses = await bonusRepository.getBalanceUser(userId)

    return bonuses
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return []
  }
}
