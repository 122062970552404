import jwt from 'jsonwebtoken'
import store from '@/store'
import authRepository from '@/repository/authRepository'

export function getToken() {
  return store.state.auth.accessToken
}

export function decodedToken(token) {
  if (!token) {
    return { userId: null }
  }
  return jwt.decode(token)
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken')
}

export function setToken(value) {
  store.commit('auth/SET_TOKEN', value)

  const { userId } = decodedToken(value)
  store.commit('user/SET_USER_ID', userId)
}

export function setRefreshToken(value) {
  localStorage.setItem('refreshToken', value)
}

export function getAppSid() {
  return store.state.app.deviceId
}

export function deleteRefreshToken() {
  localStorage.removeItem('refreshToken')
}

export function createDeviceId() {
  let deviceId = localStorage.getItem('device::sid', null)
  if (!deviceId) {
    deviceId = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 16; i++) {
      deviceId += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    localStorage.setItem('device::sid', deviceId)
  }
  store.commit('app/SET_DEVICE_ID', deviceId)
}

export function signIn(phone, password) {
  return new Promise((resolve, reject) => {
    authRepository.signIn({
      phone: phone.replace(/[^0-9]/gim, '').substring(phone.replace(/[^0-9]/gim, '').length - 10),
      password,
    }).then(response => {
      const { accessToken, refreshToken } = response
      if (!accessToken || !refreshToken) {
        throw new Error('Ошибка авторизации')
      }

      setToken(accessToken)
      setRefreshToken(refreshToken)

      resolve(true)
    }).catch(error => reject(error))
  })
}

export function refresh() {
  return new Promise((resolve, reject) => {
    const token = getRefreshToken()
    authRepository.refreshToken({ refreshToken: token }).then(response => {
      const { accessToken, refreshToken } = response
      if (!accessToken || !refreshToken) {
        throw new Error('Ошибка авторизации')
      }

      setToken(accessToken)
      setRefreshToken(refreshToken)

      resolve(true)
    }).catch(error => reject(error))
  })
}

export const register = async (_phone, firstName, code) => {
  const referralCode = localStorage.getItem('referralCode', null)

  let phone = _phone.replace(/[\D]+/g, '')
  phone = phone.substring(phone.length - 10)

  const { accessToken, refreshToken } = await authRepository.register({
    firstName, phone, code, referralCode,
  })
  if (!accessToken || !refreshToken) {
    throw new Error('Ошибка авторизации')
  }
  setToken(accessToken)
  setRefreshToken(refreshToken)
  return true
}

export const sendConfirmationCode = async (_phone, type) => {
  let phone = _phone.replace(/[\D]+/g, '')
  phone = phone.substring(phone.length - 10)
  if (type === 'register') {
    await authRepository.registerCodeCreate({ phone })
  }
  if (type === 'auth') {
    await authRepository.authCodeCreate({ phone })
  }
  return true
}

export const sendAuthCode = async (_phone, code) => {
  let phone = _phone.replace(/[\D]+/g, '')
  phone = phone.substring(phone.length - 10)

  const { accessToken, refreshToken } = await authRepository.authCode({
    phone, code,
  })
  if (!accessToken || !refreshToken) {
    throw new Error('Ошибка авторизации')
  }
  setToken(accessToken)
  setRefreshToken(refreshToken)
  return true
}
