export default [
  {
    path: '/help',
    component: {
      render(c) { return c('router-view') },
    },
    children: [
      {
        path: '',
        name: 'help',
        component: () => import('@/views/content/help/HelpList.vue'),
        meta: {
          pageTitle: 'В помощь продавцу',
          breadcrumb: [
            {
              text: 'В помощь продавцу',
              active: true,
            },
          ],
          resource: 'User',
          action: 'read',
        },
      },
      {
        path: ':id',
        name: 'help-view',
        component: () => import('@/views/content/help/HelpView.vue'),
        meta: {
          breadcrumb: [
            {
              text: 'В помощь продавцу',
              to: '/help',
            },
          ],
          resource: 'User',
          action: 'read',
        },
      },
    ],
  },
]
