import conect from './api'

const { axiosIns } = conect()

export default {
  async verifemail(...args) {
    const { error, result } = (await axiosIns.post('/user/verifemail', ...args)).data
    if (error) throw error

    return result
  },
  async getUserData(userId) {
    const { error, result } = (await axiosIns.get(`/user/${userId}`)).data
    if (error) throw error

    return result || {}
  },
  async getUserProfile(userId) {
    const { error, result } = (await axiosIns.get(`/user/${userId}/profile`)).data
    if (error) throw error

    return result
  },
  async updateUserData(userId, ...args) {
    const { error, result } = (await axiosIns.put(`/user/${userId}`, ...args)).data
    if (error) throw error

    return result
  },
  async sendPasswordCode(userId) {
    const { error, result } = (await axiosIns.post(`/user/${userId}/password`)).data
    if (error) throw error

    return result
  },
  async updateUserPassword(userId, ...args) {
    const { error, result } = (await axiosIns.put(`/user/${userId}/password`, ...args)).data
    if (error) throw error

    return result
  },
  async userList(params = {}) {
    const { error, result } = (await axiosIns.get('/user', { params })).data
    if (error) throw error

    return result
  },
  async statisticReg(params = {}) {
    const { error, result } = (await axiosIns.get('/user/statistic-new-reg', { params })).data
    if (error) throw error

    return result
  },
}
