export default [
  {
    path: '/users',
    name: 'user-list',
    component: () => import('@/views/users/list/UsersList.vue'),
    meta: {
      pageTitle: 'Список пользователей',
      breadcrumb: [
        {
          text: 'Список пользователей',
          active: true,
        },
      ],
      resource: 'UserManage',
      action: 'read',
    },
  },
  {
    path: '/user/:id',
    name: 'apps-users-view',
    component: () => import('@/views/users/profile/ViewUserProfile.vue'),
    meta: {
      pageTitle: 'Список пользователей',
      breadcrumb: [
        {
          text: 'Список пользователей',
          to: '/users',
        },
      ],
      resource: 'UserManage',
      action: 'read',
    },
  },
  {
    path: '/moderation-bonuses',
    name: 'moderation-bonuses',
    component: () => import('@/views/users/motivation/ModerationList.vue'),
    meta: {
      pageTitle: 'Список заявок на модерацию',
      resource: 'UsersMotivation',
      action: 'read',
    },
  },
  {
    path: '/moderation-bonuses/:id',
    name: 'moderation-bonuses-view',
    component: () => import('@/views/users/motivation/ModerationView.vue'),
    meta: {
      pageTitle: 'Заявка на бонусы',
      breadcrumb: [
        {
          text: 'Список заявок',
          to: '/moderation-bonuses',
        },
      ],
      resource: 'UsersMotivation',
      action: 'read',
    },
  },
]
