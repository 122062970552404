import Vue from 'vue'
import VueRouter from 'vue-router'
// export default new Ability(existingAbility || initialAbility)
// import { Ability } from '@casl/ability'
import {
  getToken, getRefreshToken, deleteRefreshToken, refresh,
} from '@/application/authService'
// eslint-disable-next-line import/no-cycle
import { fetchUserData } from '@/application/userService'
import { chekbasket } from '@/application/basketService'
import { fetchModeratorData } from '@/application/dashboardService'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import store from '@/store'

import pages from './routes/pages'
import authentication from './routes/authentication'
import profile from './routes/profile'
import users from './routes/users'
import permission from './routes/permission'
import content from './routes/content'
import basket from './routes/basket'
import product from './routes/product'
import action from './routes/action'
import saleUser from './routes/saleUser'
import bonusUser from './routes/bonusUser'
import help from './routes/help'
import emailconfirm from './routes/emailconfirm'
import salesAdm from './routes/salesAdm'
import payout from './routes/payout'
import dashboard from './routes/dashboard'
import applicationFormWebsite from './routes/applicationFormWebsite'
import company from './routes/company'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    {
      path: '/',
      name: 'main',
      component: () => import('@/views/main/client/Client.vue'),
      meta: {
        resource: 'User',
        action: 'read',
      },
    },
    ...emailconfirm,
    ...pages,
    ...authentication,
    ...profile,
    ...users,
    ...permission,
    ...content,
    ...basket,
    ...product,
    ...action,
    ...saleUser,
    ...bonusUser,
    ...help,
    ...salesAdm,
    ...payout,
    ...dashboard,
    ...applicationFormWebsite,
    ...company,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  try {
    if (to && to.redirectedFrom) {
      const { redirectedFrom } = to
      if ((redirectedFrom.split('/') || []).length > 1 && redirectedFrom.split('/')[1].indexOf('p') === 0 && String(redirectedFrom.split('/')[1].replace(/[\D]+/g, '')).length === 5) {
        localStorage.setItem('referralCode', redirectedFrom.split('/')[1].replace(/[\D]+/g, ''))
        next({ path: redirectedFrom.replace(`/${to.redirectedFrom.split('/')[1]}`, '') })
      }
      if ((redirectedFrom.split('/') || []).length > 1 && redirectedFrom.split('/')[1].indexOf('emailconfirm') === 0) {
        const emailToken = redirectedFrom.split('/')[1].replace('emailconfirm', '').trim()
        store.dispatch('appConfig/verifemail', emailToken).then().catch(() => next({ name: 'emailconfirm' }))
      }
    }
    //  http://localhost:8080/emailconfirm481abb9e1314d78d306335552261a127cc6cb5852b0
    let isLoggedIn = getToken()
    const refreshToken = getRefreshToken()

    if (!isLoggedIn && refreshToken) {
      // делаем рефреш и получаем данные на пользака
      await refresh()
      await fetchUserData()
      await fetchModeratorData()
      await chekbasket()
      isLoggedIn = getToken()
    }
    store.commit('appConfig/SET_IS_LOAD', false)

    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: 'auth-login' })
      // If logged in => not authorized
      return next({ name: 'misc-not-authorized' })
    }
    // Redirect if logged in
    // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    //   const userData = getUserData()
    //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    // }

    return next()
  } catch (error) {
    deleteRefreshToken()
    return next({ name: 'auth-login' })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
