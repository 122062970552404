export default [
  {
    path: '/sales',
    name: 'sale-list',
    component: () => import('@/views/sale/sale-list/SaleList.vue'),
    meta: {
      pageTitle: 'Мои продажи',
      breadcrumb: [
        {
          text: 'Мои продажи',
          active: true,
        },
      ],
      resource: 'User',
      action: 'read',
    },
  },
]
