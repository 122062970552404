export default [
  {
    path: '/permission/rules',
    name: 'permission-rules',
    component: () => import('@/views/permission/PermissionRules.vue'),
    meta: {
      pageTitle: 'Список правил доступа',
      breadcrumb: [
        {
          text: 'Список правил доступа',
          active: true,
        },
      ],
      resource: 'Permission',
      action: 'read',
    },
  },
  {
    path: '/permission/roles',
    name: 'permission-roles',
    component: () => import('@/views/permission/PermissionRoles.vue'),
    meta: {
      pageTitle: 'Список ролей',
      breadcrumb: [
        {
          text: 'Список ролей',
          active: true,
        },
      ],
      resource: 'Permission',
      action: 'read',
    },
  },
  {
    path: '/permission/roles/:id',
    name: 'permission-roles-view',
    component: () => import('@/views/permission/PermissionRolesView.vue'),
    meta: {
      pageTitle: 'Список правил доступа по ролям',
      breadcrumb: [
        {
          text: 'Список ролей',
          to: '/permission/roles',
        },
        {
          text: 'Правила',
          active: true,
        },
      ],
      resource: 'Permission',
      action: 'read',
    },
  },
]
