import basketRepository from '@/repository/basketRepository'
import orderRepository from '@/repository/orderRepository'
import { ref } from '@vue/composition-api'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import { currencyFormat, currencyUnFormat, receiveDateFormat } from '@/@core/utils/filter'

// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2'

export const addFoto = () => {
  const input = document.getElementById('input-file-cart')
  input.click()
}
export const handleFileChange = async e => {
  const file = e.target.files[0]
  const param = new FormData()
  param.append('file', file)
  await basketRepository.addFoto(param)
  return true
}

export const getbasketList = async userId => {
  const baskets = await basketRepository.basketList(userId)

  store.commit('basket/SET_BASKET_LIST', baskets)
  return baskets
}

export const userProfileBaskets = async userId => {
  const defaultBasketId = ref(null)

  const baskets = await basketRepository.basketList(userId)

  const list = baskets.map(x => {
    if (x.isUsed === 1) defaultBasketId.value = x.id
    const { title, id, basketTicket } = x

    return { label: title, id, basketTicket }
  })

  return { list, defaultBasketId }
}

export const getBasketById = async id => {
  try {
    const basket = await basketRepository.getBasketById(id)

    const items = basket.items.map(x => {
      const {
        articul, brand, title, ticketItemsId, createdAt,
      } = x
      return {
        articul, brand, title, ticketItemsId, createdAt,
      }
    })

    if (basket.basketTicket) {
      const { totalSum, ...etc } = basket.basketTicket
      Object.assign(basket.basketTicket, { totalSum: currencyFormat(totalSum), ...etc })
    }

    return { items, basketTicket: basket.basketTicket }
  } catch (error) {
    store.commit('app/SET_ERROR', error)
    return {}
  }
}

export const getbasketUsed = async () => {
  const basket = await basketRepository.basketUsed()

  if (basket.basketTicket) {
    const { totalSum, ...other } = basket.basketTicket

    Object.assign(basket.basketTicket, { totalSum: currencyFormat(totalSum), ...other })
  }
  store.commit('basket/SET_BASKET', basket)
}

export const chekbasket = async () => {
  await Promise.all([
    getbasketList(),
    getbasketUsed(),
  ])
}

export const basketChange = async id => { // преобразовать мультибаскет бар
  await basketRepository.basketChange({ id })
  getbasketUsed()
}

export const deleteBasketItem = async id => {
  await basketRepository.deleteBasketItem(id)
  await getbasketUsed()
}

export const relocateBasketItem = async ({ id, multibasketId }) => {
  await basketRepository.relocateBasketItem(id, { multibasketId })
  await getbasketUsed()
}

export const createBasket = async (...params) => {
  // console.log(teamId, title)
  await basketRepository.createBasket(...params)
  await chekbasket()
}

export const deleteBasket = async id => {
  await basketRepository.deleteBasket(id)
  await chekbasket()
}
export const deleteBasketTicket = id => {
  basketRepository.deleteBasketTicket(id).then(async () => { await chekbasket() })
}

export const addCashReceipt = async (...params) => {
  await basketRepository.addCashReceipt(...params)
  await chekbasket()
}

export const deleteCashReceipt = async cashReceipt => {
  try {
    const result = await Swal.fire({
      title: 'Подтверждение удаления!',
      text: 'Вы уверены в необходимости удалить чек? Востановить будет невозможно!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Удалить',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })

    if (result.value) {
      if (cashReceipt.createdUserId !== store.state.user.userId) throw new Error('У вас нет прав на удаления чека, Вы не являетесь его создателем')

      await basketRepository.deleteCashReceipt(cashReceipt.id)
      await chekbasket()
      store.commit('app/SET_MESSAGE', 'Чек успешно удален.')
    }
  } catch (error) {
    store.commit('app/SET_ERROR', error)
  }
}

export const createOrder = async () => {
  try {
    const result = await Swal.fire({
      title: 'Оформление продажи!',
      text: 'Внимание! Убедитесь, что все товары подтверждены в чеке. Не подтвержденные проигнорируются и будут утеряны.',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Отправить',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })

    if (result.value) {
      const multibasketId = store.state.basket.basket.id
      const basketIds = store.state.basket.basket.items.map(x => x.id)
      await orderRepository.createOrder({ multibasketId, basketIds })
      await chekbasket()
      store.commit('app/SET_MESSAGE', 'Заявка успешно отправлена.')
    }
  } catch (error) {
    store.commit('app/SET_ERROR', error)
  }
}

export const element = emit => {
  const isLoading = ref(false)

  const blankData = {
    fiscalDriveNumber: '',
    fiscalDocumentNumber: '',
    fiscalSign: '',
    totalSum: '',
    receiveDate: '',
  }

  const elementData = ref({})

  const resetData = () => {
    elementData.value = JSON.parse(JSON.stringify(blankData))
  }

  const {
    refFormObserver,
    clearForm,
  } = formValidation(resetData)

  const onShownSidebar = async () => {
    isLoading.value = false
  }

  const onHiddenSidebar = () => {
    clearForm()
    chekbasket()
    emit('hidden')
  }

  const onSubmit = async openElementId => {
    try {
      const { date, time, ...etc } = elementData.value
      const receiveDate = `${date} ${time}`

      elementData.value = { receiveDate, ...etc }

      const success = await refFormObserver.value.validate()
      if (success) {
        const result = await basketRepository.createBasketTicket({ multibasketId: openElementId, ...elementData.value, operationType: '1' })

        if (result) {
          emit('update:is-ticket-sidebar-active', false)
          store.commit('app/SET_MESSAGE', 'Товарный чек успешно сохранен!')
        }
      }
    } catch (error) {
      store.commit('app/SET_ERROR', error)
    }
  }

  return {
    isLoading,
    elementData,

    refFormObserver,
    clearForm,

    onShownSidebar,
    onHiddenSidebar,
    onSubmit,
  }
}

export const updateBasketTicket = (emit, ticket) => {
  const isLoading = ref(false)

  const blankData = {
    fiscalDriveNumber: '',
    fiscalDocumentNumber: '',
    fiscalSign: '',
    totalSum: '',
    receiveDate: '',
  }

  const edit = ref(false)

  const elementData = ref()

  const resetData = () => {
    elementData.value = JSON.parse(JSON.stringify(blankData))
  }
  const {
    refFormObserver,
    getValidationState,
    clearForm,
  } = formValidation(resetData)

  const onHiddenSidebar = () => {
    edit.value = false
    clearForm()
    chekbasket()
    emit('hidden')
  }

  const onSubmit = async openElementId => {
    try {
      const success = await refFormObserver.value.validate()

      if (success) {
        const actionEdit = await Swal.fire({
          title: 'Подтвердите изменение чека!',
          text: 'Вы уверены в правильности введенных данных?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Подтверждаю',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (actionEdit.value) {
          const { receiveDate, totalSum, ...etc } = ticket
          elementData.value = { receiveDate: receiveDateFormat(receiveDate), totalSum: currencyUnFormat(totalSum), ...etc }

          const result = await basketRepository.updateBasketTicket(openElementId, elementData.value)

          if (result) {
            emit('update:is-profile-basket-side-bar-active', false)
            store.commit('app/SET_MESSAGE', 'Товарный чек успешно изменен!')
          }
        }
      }
    } catch (error) {
      store.commit('app/SET_ERROR', error)
    }
  }

  return {
    isLoading,
    edit,

    refFormObserver,
    getValidationState,
    clearForm,

    onHiddenSidebar,
    onSubmit,
  }
}

export const addSellerFromTicket = async (id, companyId) => {
  try {
    const result = await Swal.fire({
      title: 'Верификация чека',
      text: 'Внимание! Вы правильно выбрали компанию - продавца?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Подтвердить',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })

    if (result.value) {
      await basketRepository.addSellerFromTicket({ id, companyId })
      store.commit('app/SET_MESSAGE', 'Успешно выполнено.')
    }
  } catch (error) {
    store.commit('app/SET_ERROR', error)
  }
}

export const ticketVerificationFromJSON = async (id, dataInJson) => {
  try {
    const result = await Swal.fire({
      title: 'Верификация чека',
      text: 'Отправить данные о чеке?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Подтвердить',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })

    if (result.value) {
      await basketRepository.ticketVerificationFromJSON({ id, dataInJson })
      store.commit('app/SET_MESSAGE', 'Успешно выполнено.')
    }
  } catch (error) {
    store.commit('app/SET_ERROR', error)
  }
}
