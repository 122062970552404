export default [
  {
    path: '/products',
    component: {
      render(c) { return c('router-view') },
    },
    children: [
      {
        path: '',
        name: 'product-list',
        component: () => import('@/views/product/product-list/ProductList.vue'),
        meta: {
          pageTitle: 'Список товаров',
          breadcrumb: [
            {
              text: 'Список товаров',
              active: true,
            },
          ],
          resource: 'Product',
          action: 'read',
        },
      },
      {
        path: ':id',
        name: 'product-view',
        component: () => import('@/views/product/product-view/ProductView.vue'),
        meta: {
          // pageTitle: 'Список товары',
          breadcrumb: [
            {
              text: 'Список товаров',
              to: '/products',
            },
          ],
          resource: 'Product',
          action: 'read',
        },
      },
    ],
  },
]
