export default [
  {
    path: '/content/help',
    component: {
      render(c) { return c('router-view') },
    },
    children: [
      {
        path: '',
        name: 'content-help',
        component: () => import('@/views/content/help/ContentHelpList.vue'),
        meta: {
          pageTitle: 'Помощь продавцу',
          breadcrumb: [
            {
              text: 'Помощь продавцу',
              active: true,
            },
          ],
          resource: 'ContentHelp',
          action: 'read',
        },
      },
      {
        path: ':id',
        name: 'content-help-view',
        component: () => import('@/views/content/help/ContentHelpView.vue'),
        meta: {
          pageTitle: 'Помощь продавцу: статья',
          breadcrumb: [
            {
              text: 'Помощь продавцу',
              to: '/content/help',
            },
            {
              text: 'Статья',
              active: true,
            },
          ],
          resource: 'ContentHelp',
          action: 'read',
        },
      },
    ],
  },
  {
    path: '/content/about',
    name: 'content-about',
    component: () => import('@/views/content/about/ContentAboutView.vue'),
    meta: {
      pageTitle: 'О клубе',
      breadcrumb: [
        {
          text: 'Информация о клубе',
          active: true,
        },
      ],
      resource: 'ContentHelp',
      action: 'read',
    },
  },
  {
    path: '/content/privacy-policy',
    name: 'content-privacy-policy',
    component: () => import('@/views/content/privacy-policy/ContentPrivacyPolicyView.vue'),
    meta: {
      pageTitle: 'Политика конфиденциальности',
      breadcrumb: [
        {
          text: 'Политика конфиденциальности',
          active: true,
        },
      ],
      resource: 'ContentHelp',
      action: 'read',
    },
  },
  {
    path: '/content/action',
    component: {
      render(c) { return c('router-view') },
    },
    children: [
      {
        path: '',
        name: 'content-action',
        component: () => import('@/views/action/action-list/ActionListAdmin.vue'),
        meta: {
          pageTitle: 'Список акций',
          resource: 'ActionManage',
          action: 'read',
        },
      },
      {
        path: ':id',
        name: 'content-action-view',
        component: () => import('@/views/action/action-view/ActionViewAdmin.vue'),
        meta: {
          pageTitle: 'Акция',
          breadcrumb: [
            {
              text: 'Список акций',
              to: '/content/action',
            },
          ],
          resource: 'ActionManage',
          action: 'read',
        },
      },
    ],
  },
  {
    path: '/content/product',
    component: {
      render(c) { return c('router-view') },
    },
    children: [
      {
        path: '',
        name: 'content-product',
        component: () => import('@/views/product/product-list-admin/ProductListAdmin.vue'),
        meta: {
          pageTitle: 'Список номенклатуры',
          resource: 'ProductManage',
          action: 'read',
        },
      },
      {
        path: ':id',
        name: 'content-product-view',
        component: () => import('@/views/action/action-view/ActionViewAdmin.vue'),
        meta: {
          pageTitle: 'Акция',
          breadcrumb: [
            {
              text: 'Список номенклатуры',
              to: '/content/product',
            },
          ],
          resource: 'ProductManage',
          action: 'read',
        },
      },
    ],
  },
  {
    path: '/content/ticket-verification-queue',
    name: 'content-ticket-verification-queue',
    component: () => import('@/views/content/ticket-verification-queue/TicketVerificationQueueList.vue'),
    meta: {
      pageTitle: 'Список очередей чеков',
      resource: 'OrderManage',
      action: 'read',
    },
  },
]
