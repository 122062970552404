import usersMotivationRepository from '@/repository/usersMotivationRepository'
import store from '@/store'
import ability from '@/libs/acl/ability'
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2'

// eslint-disable-next-line import/prefer-default-export
export const createOrderStartBonus = async (userId, refetchData) => {
  if (!ability.can('update', 'UsersMotivation')) {
    store.commit('app/SET_ERROR', 'Недостаточно прав для начисления')
    return
  }

  const result = await Swal.fire({
    title: 'Начисление по aкции - "Скачай и зарабатывай"',
    text: 'Отправить на проверку?',
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: 'Отмена',
    confirmButtonText: 'Подтвердить',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-primary ml-1',
    },
    buttonsStyling: false,
  })

  if (result.value) {
    usersMotivationRepository.createOrderStartBonus({ userId }).then(resData => {
      store.commit('app/SET_MESSAGE', resData ? 'Бонусы начислены' : 'Для начисления не выполнены условия')
      if (refetchData) {
        refetchData()
      }
    }).catch(error => {
      store.commit('app/SET_ERROR', error)
    })
  }
}

export const fetchStatisticOrderStart = async () => {
  try {
    if (ability.can('read', 'UsersMotivation')) {
      const userCreate = await usersMotivationRepository.statisticOrderStart()
      return {
        title: 'Акция - "Скачай и зарабатывай"',
        statistics: userCreate || {},
      }
    }
    return null
  } catch (error) {
    return null
  }
}
