export default {
  namespaced: true,
  state: {
    userId: null,
    userData: {
      id: null,
      firstName: '',
      lastName: '',
      phone: '',
      referralCode: null,
      role: null,
      ability: [],
      teams: [],
    },
  },
  getters: {},
  mutations: {
    SET_USER_ID(state, value) {
      state.userId = value
    },
    UPDATE_USER_DATA(state, {
      id, firstName, lastName, phone, referralCode, role, ability, teams,
    } = {}) {
      Object.assign(state.userData, {
        id, firstName, lastName, phone, referralCode, role, ability, teams,
      })
    },
    ClEAR_USER_DATA(state) {
      Object.assign(state.userData, {
        id: null,
        firstName: '',
        lastName: '',
        phone: '',
        referralCode: null,
        role: null,
        ability: [],
        teams: [],
      })
    },
  },
  actions: {},
}
