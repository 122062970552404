import conect from './api'

const { axiosIns } = conect()

export default {
  async getElementList(params = {}) {
    const { error, result } = (await axiosIns.get('/ticket-queue', { params })).data
    if (error) throw error

    return result
  },
  async deleteTicketQueue(id) {
    const { error, result } = (await axiosIns.delete(`/ticket-queue/${id}`)).data
    if (error) throw error

    return result
  },
  async elementQuantity(params = {}) {
    const { error, result } = (await axiosIns.get('/ticket-queue/quantity/', { params })).data
    if (error) throw error

    return result
  },
}
