import conect from './api'

const { axiosIns } = conect()

export default {
  async getDataUserId(userId) {
    const { error, result } = (await axiosIns.get(`/user-motivation/user/${userId}`)).data || {}
    if (error) throw error

    return result || {}
  },
  async update(id, ...args) {
    const { error, result } = (await axiosIns.put(`/user-motivation/${id}`, ...args)).data
    if (error) throw error

    return result
  },
  async chekCountModeration() {
    const { error, result } = (await axiosIns.get('/user-motivation/changed')).data
    if (error) throw error

    return result
  },
  async getElement(id) {
    const { error, result } = (await axiosIns.get(`/user-motivation/${id}`)).data
    if (error) throw error

    return result
  },
  async moderationList(params = {}) {
    const { error, result } = (await axiosIns.get('/user-motivation/', { params })).data
    if (error) throw error

    return result
  },
  async elementQuantity(params = {}) {
    const { error, result } = (await axiosIns.get('/user-motivation/quantity/', { params })).data
    if (error) throw error

    return result
  },

  async createOrderStartBonus(...args) {
    const { error, result } = (await axiosIns.post('/user-motivation/order-start/', ...args)).data
    if (error) throw error

    return result
  },
  async statisticOrderStart(params = {}) {
    const { error, result } = (await axiosIns.get('/user-motivation/statistic-order-start', { params })).data
    if (error) throw error

    return result
  },
}
