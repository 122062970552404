export default {
  namespaced: true,
  state: {
    orderCreateQuantity: 0,
    payoutCreateQuantity: 0,
    userMotivationCreateQuantity: 0,
    ticketVerificationQuantity: 0,
  },
  getters: {},
  mutations: {
    SET_ORDER_CREATE_QUANTITY(state, value) {
      state.orderCreateQuantity = !Number.isNaN(parseInt(value, 10)) ? parseInt(value, 10) : 0
    },
    SET_PAYOUT_CREATE_QUANTITY(state, value) {
      state.payoutCreateQuantity = !Number.isNaN(parseInt(value, 10)) ? parseInt(value, 10) : 0
    },
    SET_USER_MOTIVATION_CREATE_QUANTITY(state, value) {
      state.userMotivationCreateQuantity = !Number.isNaN(parseInt(value, 10)) ? parseInt(value, 10) : 0
    },
    SET_TICKET_VERIFICATION_QUANTITY(state, value) {
      state.ticketVerificationQuantity = !Number.isNaN(parseInt(value, 10)) ? parseInt(value, 10) : 0
    },
  },
  actions: {},
}
